<script setup>
import { experiences } from '@/assets/data/experience';

const mapTechToStyle = {
  stack: "bg-[#772E25]",
  dataStructures: "bg-blue-700",
  designPatterns: "bg-yellow-700",
}
</script>

<template>
  <div 
    data-main-container class="flex flex-col gap-3 md:flex-row flex-wrap bg-[#283D3B] overflow-y-auto p-3 md:justify-center items-center min-h-screen font-mono">



    <div 
      v-for="experience in experiences" 
      :key="experience.id" 
      class="experience-card min-h-[500px] md:h-[700px] w-[350px] md:w-[400px] bg-[#2e4b48] text-[#EDDDD4]  shadow-2xl p-2  border-2 border-[#EDDDD4]">
      
      <div class="flex flex-col h-full text-center">
        <a  v-html="experience.company" :href="experience.site"  target="_blank" class="text-xl font-semibold hover:text-green-400 transition-colors cursor-pointer"></a>

        <small class="text-[0.5em] md:text-[0.75em]" v-html="experience.role"/>
        <small class="text-[0.5em] md:text-[0.75em] italic bold" v-html="experience.duration"/>
        
        <!-- Loop through technologies -->
        <div class="flex flex-wrap mt-4">
          <small 
            v-for="(tech, index) in [
              ...(experience.tech.stack || []), 
              ...(experience.tech.dataStructures || []), 
              ...(experience.tech.designPatterns || [])
            ]" 
            :key="index" 
            class="p-1 rounded m-1 text-[0.5em] md:text-[0.7em] text-white inline-block"
            :class="[
              experience.tech.stack.includes(tech) ? mapTechToStyle.stack : '',
              experience.tech.dataStructures.includes(tech) ? mapTechToStyle.dataStructures : '',
              experience.tech.designPatterns.includes(tech) ? mapTechToStyle.designPatterns : ''
            ]"
          >
            {{ tech }}
          </small>
        </div>
        
        <p class="text-left text-[0.8em] md:text-[1em] leading-4 mt-8 px-2" v-html="experience.description"/>
      </div>
    </div>
  </div>
  
</template>


<style>
.tech-animation {
  opacity: 0;
  animation: fadeInTech 1s ease-in-out forwards;
}

.experience-card {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  transition: transform 0.3s, box-shadow 0.3s;
}

@keyframes fadeInTech {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
