export const experiences = [
  {
    id: 1,
    company: 'Triumph Advertising',
    site: 'https://triumphadvertisinginc.com',
    role: 'Full Stack Software Developer',
    duration: 'November 2022 - December 2024',
    tech: {
      stack: ["mysql","laravel 11", "inertia", "vue 3"],
      dataStructures: [],
      designPatterns: []
    },
    description: 'As a full-stack developer with expertise in Vue 3 and PHP using Laravel, I played a pivotal role in optimizing a system for content managers (CMs) to create customized checkout processes for clients. I developed a scalable 4-step system (landing page, checkout, upsell, thank-you page) by introducing a Singleton class, which eliminated the need for developers to repeatedly rewrite front-end logic, significantly reducing development time. <br><br> I also built a permission-based navigation system using Inertia to dynamically fetch user permissions and map component access via a static JavaScript class.<br><br> Additionally, I implemented an email notification system using Mailtrap API to automate order confirmation emails to customers. <br><br>These solutions streamlined development, boosted team productivity, and enhanced scalability, making the system more efficient and adaptable for future client needs.'
  },
];
